import React, { PureComponent, useEffect, useState, memo } from 'react';
import { Button, CardBody, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Link, Navigate } from 'react-router-dom';
import { useParams } from "react-router";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from 'moment';

import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { load as loadAccount } from '../../../redux/reducers/commonReducer';
// import {settingsAction} from '../../../../redux/actions/settingsAction'
import { settings } from '../../../redux/actions/settingsAction'
import PropTypes from 'prop-types';

import renderCheckBoxField from '../../../shared/components/form/CheckBox';

// Validate input
// import validate from '../../Validations/validate';
import validate from '../../Validations/validate';

// Input field
import DefaultInput from '../../../shared/components/form/DefaultInput';
import DoInputField from '../../Form/Fields/DoInputField';
import DoPasswordField from '../../Form/Fields/DoPasswordField';
import DoPatientPhoneField from '../../Form/Fields/DoPatientPhone';
import DoDateField from '../../Form/Fields/DoDateField';
import DoSelectField from '../../Form/Fields/DoSelectField';
import DoRadioButtons from '../../Form/Fields/DoRadioButtons';
import DoMultiSelectField from '../../Form/Fields/DoMultiSelectField';

// Config
import configMessage from '../../../config/configMessages';
import fetchRequest from '../../../config/service';
import apiCalls from '../../../config/apiCalls';
import config from '../../../config/config';
import GoogleLogin from 'react-google-login'

// Toaster message
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
import jwt_decode from "jwt-decode"
import ReCAPTCHA from "react-google-recaptcha";
import {
  browserName,
  osName, osVersion, deviceType
} from "react-device-detect";
// Loader
import Loader from '../../App/Loader';
import { useForm, Controller } from 'react-hook-form';
import PasswordField from '@/shared/components/form/password';

import formatDate from '../../UI/FormatDate/formatDate';
import FamilyMembers from './FamilyMembers';



const publicIp = require('public-ip');


let newpassval = value => (value ? config.passwordRegex.test(value) ? undefined : 'Not a secure paswword, hint:User1234$' : configMessage.fillField)
const newpassvalLength = value => (value ? value.length >= 8 ? undefined : 'Password length should be atleast 8' : configMessage.fillField)

const required = value => (value || typeof value === 'string' ? undefined : configMessage.fillField)
const normalizePhone = (value) => {
  if (!value) {
    return value
  }
  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length <= 3) {
    return onlyNums
  }
  if (onlyNums.length <= 7) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`
}

// Memoize the field components for performance optimization
const MemoizedDoInputField = memo(DoInputField);
const MemoizedDoPatientPhoneField = memo(DoPatientPhoneField);
const MemoizedDoDateField = memo(DoDateField);
const MemoizedDoSelectField = memo(DoSelectField);
const MemoizedDoRadioButtons = memo(DoRadioButtons);
const MemoizedMultiselectField = memo(DoMultiSelectField);
const Register = (props) => {

  const [deviceInfo, setDeviceInfo] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [isLoginDisabled, setIsLoginDisabled] = useState(config.displayRecaptcha ? true : false);
  const [loginRememberInfo, setLoginRememberInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isRobot, setIsRobot] = useState(false);
  const [isLoginSuccess, setIsLoginSuccess] = useState(false);
  const [isNotChecked, setIsNotChecked] = useState(false);
  const [remember_me, setRemember_me] = useState(false);
  const [loginRole, setLoginRole] = useState('');
  const [captchaValue, setCaptchaValue] = useState(null);
  const [settingsData, setSettingsData] = useState({});
  const [isGoogleLoginSuccess, setIsGoogleLoginSuccess] = useState('');
  const [userDetails, setUserDetails] = useState({});
  const [displayDetailsModal, setDisplayDetailsModal] = useState(false);
  const [stateOptions, setStateOptions] = useState();
  let [schema, setSchema] = useState('');
  const [selectedFamilyMembers, setSelectedFamilyMembers] = useState([]);
  const [phoneFieldVisibility, setPhoneFieldVisibility] = useState({
    father: false,
    mother: false,
    brotherOrSister: false,
    other: false,
  });
  const params = useParams();

  const formFields = [
    {
      name: "firstName",
      type: "text",
      placeholder: "First Name",
      label: "First Name",
      id: "firstName",
      required: true,
      show: true
    },
    {
      name: "lastName",
      type: "text",
      placeholder: "Last Name",
      label: "Last Name",
      id: "lastName",
      required: true,
      show: true
    },
    {
      name: "email",
      type: "email",
      placeholder: "Email",
      label: "Email",
      id: "email",
      required: true,
      show: true,
      enableEmails: true
    },
    {
      name: "gender",
      type: "radio",
      placeholder: "Gender",
      label: "Gender",
      id: 'gender',
      required: true,
      value: 'Unknown',
      show: true,
      options: [
        { label: "Male", value: "Male", color: "primary" },
        { label: "Female", value: "Female", color: "primary" },
        { label: "Unknown", value: "Unknown", color: "primary" },
      ],
    },
    {
      name: "dateOfBirth",
      type: "date",
      label: "DOB",
      id: "dateOfBirth",
      required: true,
      maxDate: true,
      dateFormat: config.dateDBFormat,
      customValidation: dOBValidation,
      placeholder: config.dateDBFormat,
      show: true
    },
    {
      name: "phoneNumber",
      type: 'number',
      placeholder: "Phone",
      label: "Phone",
      maxLength: config.phoneNumberLength,
      id: "phoneNumber",
      fieldType: 'phone',
      required: true,
      maxLength: config.phoneNumberLength,
      show: true,
      enableTexts: true,
      enableCalls: true
    },
    {
      name: "address",
      type: "text",
      placeholder: "Address",
      label: "Address",
      id: "address",
      required: true,
      show: true
    },

    {
      name: "city",
      type: "text",
      placeholder: "City",
      label: "City",
      id: "city",
      required: true,
      show: true
    },
    {
      name: "state",
      type: "dropDown",
      placeholder: "State",
      label: "State",
      id: "state",
      required: true,
      options: stateOptions,
      filter: true,
      itemTemplate: true,
      show: true
    },
    {
      name: "zipCode",
      type: "number",
      placeholder: "ZIP Code",
      label: "ZIP Code",
      id: "zipCode",
      required: true,
      fieldType: 'zipCode',
      maxLength: config.zipCodeLength,
      show: true
    },
    {
      name: "familyMemberPhone",
      type: "dropDown",
      label: "Family Members Phone",
      isMultiSelect: true,
      id: "familyMemberPhone",
      required: false,
      placeholder: "Select family members",
      show: true,
      options: [
        { label: "Father", value: "Father" },
        { label: "Mother", value: "Mother" },
        { label: "Brother/Sister", value: "Brother/Sister" },
        { label: "Other", value: "Other" },
      ],
      onChange: setSelectedFamilyMembers
    },
    {
      name: "fatherPhoneNumber",
      type: "number",
      placeholder: "Father Phone Number",
      label: "Father Phone Number",
      maxLength: 10,
      required: phoneFieldVisibility?.father,
      show:phoneFieldVisibility?.father
    },
    {
      name: "motherPhoneNumber",
      type: "number",
      placeholder: "Mother Phone Number",
      label: "Mother Phone Number",
      maxLength: 10,
      required: phoneFieldVisibility?.mother,
      show:phoneFieldVisibility?.mother
    },
    {
      name: "brotherOrSisterPhoneNumber",
      type: "number",
      placeholder: "Brother or Sister Phone Number",
      label: "Brother or Sister Phone Number",
      maxLength: 10,
      required: phoneFieldVisibility?.brotherOrSister,
      show:phoneFieldVisibility?.brotherOrSister
    },
    {
      name: "otherPhoneNumber",
      type: "number",
      placeholder: "Other Phone Number",
      label: "Other Phone Number",
      maxLength: 10,
      required: phoneFieldVisibility?.other,
      show:phoneFieldVisibility?.other
    },
    {
      name: "familyMember",
      type: "customType",
      customType: FamilyMembers,
      placeholder: "familyMember",
      label: "Family Member",
      id: "familyMember",
      customWidthClass: 'col-md-12 col-sm-12',
      show: true
    },

  ]

  useEffect(() => {
    const previousVisibility = phoneFieldVisibility;
    const newVisibility = {
      father: selectedFamilyMembers?.includes("Father"),
      mother: selectedFamilyMembers?.includes("Mother"),
      brotherOrSister: selectedFamilyMembers?.includes("Brother/Sister"),
      other: selectedFamilyMembers?.includes("Other"),
    };
    setPhoneFieldVisibility(newVisibility);

    // Check changes from true to false and reset if needed
    if (previousVisibility.brotherOrSister && !newVisibility.brotherOrSister) {
      setValue("brotherOrSisterPhoneNumber", undefined);
    }
    if (previousVisibility.father && !newVisibility.father) {
      setValue("fatherPhoneNumber", undefined);
    }
    if (previousVisibility.mother && !newVisibility.mother) {
      setValue("motherPhoneNumber", undefined);
    }
    if (previousVisibility.other && !newVisibility.other) {
      setValue("otherPhoneNumber", undefined);
    }

  }, [selectedFamilyMembers]);


  useEffect(() => {

    if (params.clinicId) {
      setValue('clinicId', { _id: params.clinicId });
    }

    if (localStorage.StatesOptions) {
      setStateOptions(JSON.parse(localStorage.StatesOptions));
    }

    getSettingsData();
    setDeviceDetails();
    const schema = setValidations(formFields, true);
    setSchema(schema);
  }, []);
 
  const setDeviceDetails = async () => {
    await setDeviceInfo({
      // ipAddress:ipAddr,
      browserName: browserName,
      osName: osName,
      osVersion: osVersion,
      deviceType: deviceType
    });
    // let ipAddr = await publicIp.v4();  
  }

  function dOBValidation(yup) {
    return yup.date()
      .required('Date of Birth is required')
      .test('age', 'Age must be between 1 and 100 years', (value) => {
        const today = new Date();
        const birthDate = new Date(value);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();

        // Adjust age if the birth date hasn't occurred yet this year
        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }

        return age >= 1 && age <= 100;
      })
  }


  const {
    handleSubmit,
    register,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });
  
  useEffect(() => {
    setTimeout(() => {
      const schema = setValidations(formFields);
      setSchema(schema);
    }, 180);
   
  }, [phoneFieldVisibility]);

  let setValidations = (fields, initial) => {
    let valObj = {};
    fields.forEach((field) => {
      if (field.value && initial) setValue(field.name, field.value);
      if (field.required || field.type == 'email' || field.fieldType === 'zipCode') {
        valObj[field.name] = getValidations(field);
      }
    });

    return yup.object().shape(valObj)
  };

  let getValidations = (field) => {
    const errMsg = `${field.label} is a required field`;
    if (field.customValidation) {
      return field.customValidation(yup);
    }
    else if (field.type === 'number') {
      if (field.fieldType === 'phone') {
        return yup.string().length(config.phoneNumberLength, `Enter the Valid ${field.label}`).required(errMsg);
      } else if (field.fieldType === 'zipCode') {
        if (field.required) {
          return yup.string()
            .length(config.zipCodeLength, `Enter a valid ${field.label} of exactly ${config.zipCodeLength} characters`)
            .required(errMsg);
        }
        return yup.string()
          .test('length', `Enter a valid ${field.label} of exactly ${config.zipCodeLength} characters`, (value) => {
            return !value || value.length === config.zipCodeLength;
          });
      }
      return yup.number().required(errMsg);
    } else if (field.type === 'email') {
      if (field.required) {
        return yup.string().email().required(errMsg).test({
          name: 'email-regex',
          test: function (value, { originalValue }) {
            if (originalValue) {
              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
              if (!emailRegex.test(originalValue)) {
                throw this.createError({ path: field.name, message: 'Enter a valid email id' });
              }
            }
            return true;
          },
        });
      }

      return yup.string().test({
        name: 'email-regex',
        test: function (value, { originalValue }) {
          if (originalValue) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(originalValue)) {
              throw this.createError({ path: field.name, message: 'Enter a valid email id' });
            }
          }
          return true;
        },
      });

    } else if (field.type === 'phone') {
      return yup.string().length(config.phoneNumberLength, `Enter the Valid ${field.label}`).required(errMsg)
    } else if (field.type === "relateAutoComplete") {
      return yup.object().typeError('Please select the value from dropdown').required(errMsg);
    }
    return yup.string().required(errMsg);
  }


  const submit = (values) => {

    if (values.dateOfBirth) {
      values.Dob = moment(values.dateOfBirth).format(config.dateDBFormat);
    }

    for (let formField of formFields) {
      if (formField.type === 'date' && values[formField.name]) {
        values[formField.name] = !isNaN((new Date(values[formField.name])).getTime()) ? formatDate.formatDate(values[formField.name]) : undefined;
      }
    }

    values.deviceInfo = deviceInfo;
    return fetchRequest('POST', apiCalls.register, values)
      .then(async (response) => {
        if (response && response.respCode && response.respCode == 204) {
          setIsLoginSuccess(true);
          setIsNotChecked(false);
          let userDetails = response.details
          if (response.accessToken) {
            let tokenInfo = {
              accessToken: response.accessToken,
              refreshToken: response.refreshToken,
            };
            userDetails = { ...userDetails, ...tokenInfo };
            // save user credentials in storage
            localStorage.setItem('loginCredentials', JSON.stringify(userDetails));

          }
          showToasterMessage("Patient registered successfully.", 'success');
        } else if (response && response.errorMessage) {
          showToasterMessage(response.errorMessage, 'error');
        }

      })
  }

  const onChange = (value) => {
    if (config.displayRecaptcha) {
      setCaptchaValue(value ? value : null);
      setIsLoginDisabled(value ? false : true);
    }
  }

  const getSettingsData = () => {
    let settings;
    fetchRequest('GET', `settings`).then(async (response) => {
      if (response && response.respCode) {
        settings = response.settings[0]
        settings["GoogleClientId"] = settings["Patients"].GoogleClientId;
        settings["GooglesecretKey"] = settings["Patients"].GooglesecretKey;
        setSettingsData(settings);
        let arr = settings.states.map(option => { return { label: option.split('-')[1].trim(), value: option, displayLabel: option } });
        setStateOptions(arr);
      }

    })
    return settingsData;
  }

  const loadGoogleComponent = (self) => {
    function handleCredentialResponse(response) {
      var data = jwt_decode(response.credential);
      if (data.email && data.name && config.displayGoogleLogin) {
        return fetchRequest('POST', 'auth/socialLogin', { name: data.name, email: data.email, role: "Admin", entityType: config.entityType, deviceInfo: self.state.deviceInfo })
          .then(async (response) => {
            if (response && response.respCode && response.respCode == 200) {
              self.setState({
                isLoading: true
              });
              let userDetails = response.details;
              if (userDetails && userDetails._id) {
                if (response.accessToken) {
                  let tokenInfo = {
                    accessToken: response.accessToken,
                    refreshToken: response.refreshToken,
                    tokenExpires: response.tokenExpires,
                  };
                  userDetails = { ...userDetails, ...tokenInfo };

                  // save user credentials in storage
                  localStorage.setItem('loginCredentials', JSON.stringify(userDetails));
                }
                if (userDetails && userDetails.rolePermissions) {
                  let permissions = userDetails.rolePermissions;
                  // save user permissions in storage
                  localStorage.setItem('rolePermissions', JSON.stringify(permissions));
                }
              }

              showToasterMessage(response.respMessage, 'success');

              if (userDetails.firstTimeLogin) {
                self.setState({
                  displayDetailsModal: true,
                  isLoading: false,
                  userDetails: userDetails
                })
                if (self.props.load) {
                  setTimeout(() => {
                    self.props.load(self.state.userDetails)
                  }, 300);
                }

              } else {
                self.setState({
                  isLoginSuccess: true,
                  isGoogleLoginSuccess: true,
                })
              }
            } else if (response && response.errorMessage) {
              setTimeout(() => {
                self.setState({
                  isLoginDisabled: false,
                  isLoading: false
                })
              }, 250);
              showToasterMessage(response.errorMessage, 'error');
            }
            // settingsAction
          }).catch((err) => {
            self.setState({
              isLoginDisabled: false,
              isLoading: false
            });
          });
      }
    }
    if (config.displayGoogleLogin) {
      // window.onload = function () {
      if (self.state.settingsData && self.state.settingsData.GoogleClientId) {
        let GoogleClientId = self.state.settingsData && self.state.settingsData.GoogleClientId ? self.state.settingsData.GoogleClientId : null
        /* global google */
        google.accounts.id.initialize({
          client_id: GoogleClientId,
          callback: handleCredentialResponse
        });
        google.accounts.id.renderButton(
          document.getElementById("loginButton"),
          { theme: "outline", size: "large", width: document.getElementById('googleButton').clientWidth }  // customization attributes
        );
        // google.accounts.id.prompt(); // also display the One Tap dialog
      }
      // }
    }
    return true
  }

  const cancelReset = async type => {
    setIsLoginSuccess(false);
    setIsGoogleLoginSuccess(false);
    setDisplayDetailsModal(false);
    localStorage.removeItem("rolePermissions");
    localStorage.removeItem("loginCredentials");
    reset();
  }

  const handleModalSubmit = async formValues => {
    if (formValues) {
      delete formValues.email;
      delete formValues.password;
      formValues["firstTimeLogin"] = false
      let Url;
      if (localStorage.getItem('loginCredentials')) {
        let user = JSON.parse(localStorage.getItem('loginCredentials'));
        Url = `${apiCalls["Employees"]}/${user._id}`;
      }
      return fetchRequest('PUT', Url, formValues)
        .then(async (response) => {
          if (response && response.respCode && response.respCode === 205) {
            showToasterMessage(response.respMessage, 'success');
            setIsLoginSuccess(false);
            setIsGoogleLoginSuccess(true);
            setDisplayDetailsModal(false);
          } else if (response && response.errorMessage) {
            setIsLoginSuccess(false);
            setIsGoogleLoginSuccess(false);
            setDisplayDetailsModal(false);
            showToasterMessage(response.errorMessage, 'error');
          }
        }).catch((err) => {
          setIsLoginSuccess(false);
          setIsGoogleLoginSuccess(false);
          setDisplayDetailsModal(false);
          return err;
        });

    } else {
      setIsLoginSuccess(false);
      setIsGoogleLoginSuccess(false);
      setDisplayDetailsModal(false);
      return;
    }

  }

  const getFieldComponent = (item) => {
    switch (item.fieldType || item.type) {
      case 'phone': return MemoizedDoPatientPhoneField;
      case 'date': return MemoizedDoDateField;
      case 'dropDown': return item?.isMultiSelect ? MemoizedMultiselectField : MemoizedDoSelectField;
      case 'radio': return MemoizedDoRadioButtons;
      default: return MemoizedDoInputField;
    }
  };

  const getField = ({ field, fieldState }, item, customErrors) => {

    const componentProps = {
      markReq: item.required,
      input: field,
      item: item,
      id: field.id,
      name: field.name,
      label: item.label,
      maxDate: item.maxDate,
      options: item.options,
      type: item.type === 'number' ? 'number' : 'text',
      fieldState,
      errors: customErrors ? customErrors : errors,
      control,
      formProps: props,
      defVal: item.value,
      placeholder: item.placeholder || null,
      onChange: item?.onChange,
      getValues,
      setValue,
      watch,
      field,
      fieldState,
      stateOptions,
      getFieldComponent,
      setValidations,
      getField,
    }

    const FieldComponent = item.type === "customType" && item.customType ? item.customType : getFieldComponent(item);

    return <FieldComponent
      {...componentProps}
    />
  }

  return (
    <span>
      {displayDetailsModal ?
        <Modal
          isOpen={displayDetailsModal}
          toggle={cancelReset}
          centered
          className={`modal-dialog modal-dialog-centered modal-dialog--primary  modal-dialog--header`}
        >
          <ModalHeader className="modal__header">
            <button
              className="lnr lnr-cross modal__close-btn"
              type="button"
              onClick={cancelReset}
            />
            <p className="bold-text  modal__title"> {"Details"} </p>
          </ModalHeader>
          <ModalBody className="p-2">
            <Loader loader={isLoading} />

            <form className="form" onSubmit={handleSubmit(handleModalSubmit)}>
              <div className="row mx-1 mt-3 " style={{ width: '100%' }}>

                {
                  formFields && formFields.length > 0 &&
                  formFields.map((item, index) => {
                    if (item?.show) {
                      return (
                        <div className="col-sm-12 col-md-6">
                          <div className="form__form-group">
                            <span className="form__form-group-label">{item.label}</span>
                            <div className="form__form-group-field">
                              <input
                                name={item.name}
                                type="text"
                                placeholder={item.placeholder}
                                {...register(`${item.name}`)}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })
                }
                <div className="col-sm-12 text-center pt-3">
                  <div>
                    <Button
                      outline
                      color="primary"
                      type="buttom"
                      onClick={cancelReset}
                    >
                      Cancel
                    </Button>

                    <Button color="primary" type="submit">
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </ModalBody>
        </Modal>
        :
        <div>
          < form className="form" autocomplete="off" onSubmit={handleSubmit(submit)}>
            <Loader loader={isLoading} />
            <div className='w-100 d-flex flex-wrap'>
              {
                formFields && formFields.length > 0 ?
                  formFields.map((item, index) => {
                    if (item.show) {
                      return (
                        <div className={`pb-2 px-2 ${item.customWidthClass ? item.customWidthClass : 'col-sm-12 col-md-6'}`}>
                          <div>
                            <Controller
                              name={item.name}
                              control={control}
                              render={(field) => getField(field, item)} />
                          </div>
                        </div>
                      );
                    }
                    return null;
                  }) : null
              }

            </div>


            <div className="w-100 mt-2">
              {config.displayRecaptcha && settingsData && settingsData.GooglesecretKey ?
                < ReCAPTCHA
                  sitekey={settingsData && settingsData.GooglesecretKey}
                  // sitekey={"6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"}
                  onChange={onChange}
                  type="image"
                  className='recap'
                />
                : null}
            </div>
            <div className="account__btns" style={{ margin: 'auto', width: '100%' }}>
              <Button
                className=" mt-2 mb-0"
                color="primary"
                disabled={isLoginDisabled}
                style={{ width: '30%' }}
              >
                Register
              </Button>
            </div>
            {config.displayGoogleLogin ?
              <div className='line mt-4'>
                <div className='text'>
                  or
                </div>
              </div>
              : null
            }
            <div className='d-flex flex-column w-100 pb-3'>
              {config.displayGoogleLogin ?
                <div id="googleButton">
                  <div id="loginButton" className="strike w-100"></div>
                </div>
                : null
              }
            </div>
            <div className='py-2'>
              <p>
                Back to <Link to="/log_in">Login</Link>
              </p>
            </div>
            {isLoginSuccess ? <Navigate to="/log_in" /> : null}
            {isGoogleLoginSuccess ? <Navigate to="/patients" /> : null}
          </form>
        </div>
      }
    </span >
  );
}

export default Register;
