import React from 'react';
import LogInForm from './components/LogInForm';
import config from '../../../config/config';
import configImages from '../../../config/configImages';
import LogoUI from '../../LogoUI/logoUI'
const LogIn = () => (

  //~ New Login modals
  <div>
    {config.selectedLoginScreenName && config.selectedLoginScreenName == "1" ?
      <div className='common-login-container'>
        <div className='login1'>
          <div className='card'>
            <div className='card-body'>
              <div className='text-center w-100 pb-4 '>
                {/* <img src={configImages.loginImage} className='login-logo'></img> */}
                <h4 style={{ color: "rgb(60, 97, 119)", fontWeight: "bold" }}>{config.appName}</h4>
              </div>
              <LogInForm onSubmit />
            </div >
          </div >
        </div >
      </div >
      : config.selectedLoginScreenName && config.selectedLoginScreenName == "2" ?
        <div className='common-login-container'>
          <div className='login-2 card'>
            <div className='login-img'>
              {/* <img src={configImages.loginSideImage} alt="" className='h-100' /> */}
              <h4 style={{ color: "rgb(60, 97, 119)", fontWeight: "bold" }}>{config.appName}</h4>
            </div>
            <div className='card login2-form border-left'>
              <div className='card-body'>
                <div className='text-center w-100 pb-4 '>
                  <img src={configImages.loginImage} className='login-logo'></img>
                </div>
                <LogInForm onSubmit />
              </div>
            </div>
          </div>
        </div>
        : config.selectedLoginScreenName && config.selectedLoginScreenName == "3" ?
          <div className='common-login-container'>
            <div className='login-2 card'>
              <div className='card login2-form border-right'>
                <div className='card-body'>
                  <div className='text-center w-100 pb-4 '>
                    {/* <img src={configImages.loginImage} className='login-logo'></img> */}
                    <h4 style={{ color: "rgb(60, 97, 119)", fontWeight: "bold" }}>{config.appName}</h4>
                  </div>
                  <LogInForm onSubmit />
                </div>
              </div>
              <div className='login-img'>
                <img src={configImages.loginSideImage} alt="" className='h-100' />
              </div>
            </div>
          </div>
          : config.selectedLoginScreenName && config.selectedLoginScreenName == "4" ?
            < div className='common-login-container' >
              <div className='login-4'>
                <div className='login-side-logo'>
                  {/* <img src={configImages.loginImage} alt="" className='h-100' /> */}
                  <h4 style={{ color: "rgb(60, 97, 119)", fontWeight: "bold" }}>{config.appName}</h4>
                </div>
                <div className='border-left login-form'>
                  <div className='p-3'>
                    <LogInForm onSubmit />
                  </div>
                </div>
              </div>
            </div >
            : null}
  </div >
);

export default LogIn;
