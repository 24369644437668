import React, { useEffect, useState } from "react";
import { InputText } from 'primereact/inputtext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { allCountries } from 'country-telephone-data';
import { Controller } from 'react-hook-form';
import { Tooltip } from 'primereact/tooltip';
import '../../../scss/component/phonenumber.scss';
import config from "../../../config/config";

const DoPatientPhoneField = (props) => {

  const { id, label, name, type = 'text', placeholder, style, field, item = {}, control, errors, setValue, getValues, markReq } = props;
  const disabled = item.disabled;

  const [selectedCountry, setSelectedCountry] = useState(getInitialCountry());
  const [phoneNumber, setPhoneNumber] = useState("");

  const tooltipTexts = "Enable Texts.";
  const tooltipCalls = "Enable Calls.";

  useEffect(() => {
    setValue("countryName", selectedCountry);
  }, [selectedCountry, setValue]);

  function getInitialCountry() {
    const initialCountry = getValues("countryName") || 'us';
    return allCountries.find(c => c.iso2 === initialCountry)?.iso2 || 'us';
  };

  const handleCountryChange = (option) => {
    const countryValue = option?.value || selectedCountry;
    setSelectedCountry(countryValue);
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
    setValue(name, e.target.value);
  };

  const markRequired = () => (
    <FontAwesomeIcon color='red' icon={faAsterisk} style={{ width: '7px', marginBottom: "5px", marginLeft: "2px" }} />
  );

  const countryOptions = React.useMemo(() => allCountries.map((country) => ({
    label: `${country.name} (+${country.dialCode})`,
    value: country.iso2,
    icon: `${config.phoneNumberCountryUrl}w20/${country.iso2.toLowerCase()}.png`,
    code: `+${country.dialCode}`,
  })), []);

  const countryOptionTemplate = (option) => (
    <div className="flex align-items-center">
      <img alt={option?.label} src={`${config.phoneNumberCountryUrl}w40/${option?.value.toLowerCase()}.png`}
        onError={(e) => e.target.src = '${config.phoneNumberCountryUrl}w40/xx.png'}
        style={{ marginRight: '10px', verticalAlign: 'middle', height: '40px', width: '40px' }} />
      {option?.label}
    </div>
  );

  const selectedCountryTemplate = (option) => option ? (
    <div className="d-flex align-items-center gap-2 h-100">
      <img alt={option?.label} src={`${config.phoneNumberCountryUrl}w20/${option.value.toLowerCase()}.png`}
        onError={(e) => e.target.src = '${config.phoneNumberCountryUrl}w20/xx.png'} className="flagIcon" />
      <div>{`+${allCountries.find(c => c.iso2 === option.value)?.dialCode}`}</div>
    </div>
  ) : "Select a country";

  const renderCountryCodePhone = () => (
    <div className="flex phone-field-component">
      {/* Country dropdown */}
      <div className="phone-input flex" style={{ width: "100%" }}>
        <div className="country-code-dropdown" style={{ flex: 1,maxWidth: 'fit-content'}}>
          <Controller
            name="countryName"
            control={control}
            render={({ field }) => (
              <Dropdown
                value={getValues("countryName") || selectedCountry}
                options={countryOptions}
                onChange={(e) => {
                  field.onChange(e.value);
                  handleCountryChange(e);
                }}
                valueTemplate={selectedCountryTemplate}
                itemTemplate={countryOptionTemplate}
                placeholder="Select a country"
                className="p-dropdown"
                filter
                disabled={disabled}
                showClear={false}
              />
            )}
          />
        </div>
        <div className="phone-number-input" style={{ flex: 3, maxWidth: item?.enableCalls ? "56%" : "68%" }}>
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <InputText
                type="number"
                value={phoneNumber || getValues(name)}
                onChange={(e) => {
                  if (!item.maxLength || item.maxLength >= e.target.value.length) {
                    field.onChange(e);
                    handlePhoneNumberChange(e);
                  }
                }}
                placeholder="Enter phone number"
                className="p-inputtext"
                disabled={disabled}
              />
            )}
          />
        </div>
      </div>

      {/* Enable Texts and Enable Calls switches */}
      {item.enableTexts && renderSwitch("enableTexts", tooltipTexts)}
      {item.enableCalls && renderSwitch("enableCalls", tooltipCalls)}
    </div>
  );

  const renderSwitch = (name, tooltip) => (
    <div style={{ width: "13%", display: "flex", alignItems: "center",marginLeft:'5px' }}>
      <Controller
        name={name}
        control={control}
        defaultValue={getValues(name) || true}
        render={({ field: { onChange, value } }) => (
          <>
            <InputSwitch id={name} checked={value} onChange={(e) => onChange(e.value)} />
            <Tooltip target={`#${name}`} content={tooltip} />
          </>
        )}
      />
    </div>
  );

  return (
    <div className="flex flex-column">
      <div className="flex align-center">
        {!item.disableLabel && (
          <>
            <label htmlFor={id} className="text-capitalize">{label}</label>
            {markReq && markRequired()}
          </>
        )}
      </div>
      {renderCountryCodePhone()}
      <small className="text-danger">{errors?.[name]?.message}</small>
    </div>
  );
};

export default DoPatientPhoneField;
