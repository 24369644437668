import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import config from '../../../config/config';
import { yupResolver } from '@hookform/resolvers/yup';

const FamilyMembers = (props) => {
    const { field, stateOptions, getFieldComponent, setValidations, getField } = props;
    const [visible, setVisible] = useState(false); // For modal visibility
    const [editMember, setEditMember] = useState(null); // For editing a member
    let [schema, setSchema] = useState('');

    const familyRelationOptions = [
        { label: "Brother", value: "Brother" },
        { label: "Father", value: "Father" },
        { label: "Mother", value: "Mother" },
        { label: "Child", value: "Child" },
        { label: "CareGiver", value: "CareGiver" },
        { label: "FosterChild", value: "FosterChild" },
        { label: "Friend", value: "Friend" },
        { label: "Grandchild", value: "Grandchild" },
        { label: "Grandfather", value: "Grandfather" },
        { label: "Grandmother", value: "Grandmother" },
        { label: "Grandparent", value: "Grandparent" },
        { label: "Guardian", value: "Guardian" },
        { label: "LifePartner", value: "LifePartner" },
        { label: "Other", value: "Other" },
        { label: "Parent", value: "Parent" },
        { label: "Self", value: "Self" },
        { label: "Sibling", value: "Sibling" },
        { label: "Sister", value: "Sister" },
        { label: "Sitter", value: "Sitter" },
        { label: "Spouse", value: "Spouse" },
        { label: "Stepchild", value: "Stepchild" },
        { label: "Stepfather", value: "Stepfather" },
        { label: "Stepmother", value: "Stepmother" },
    ];

    const formFields = [
        {
            name: "firstName",
            type: "text",
            placeholder: "First Name",
            label: "First Name",
            id: "firstName",
            required: true,
        },
        {
            name: "lastName",
            type: "text",
            placeholder: "Last Name",
            label: "Last Name",
            id: "lastName",
            required: true,
        },
        {
            name: "relationship",
            type: "dropDown",
            placeholder: "Relationship",
            label: "Relation Ship",
            id: "relationship",
            required: true,
            options: familyRelationOptions,
        },

        {
            name: "address",
            type: "text",
            placeholder: "Address",
            label: "Address",
            required: true,
        },
        {
            name: "address2",
            type: "text",
            placeholder: "Address2",
            label: "Address2",
            required: false,
        },
        {
            name: "city",
            type: "text",
            placeholder: "City",
            label: "City",
            id: "city",
        },
        {
            name: "state",
            type: "dropDown",
            placeholder: "State",
            label: "State",
            id: "state",
            options: stateOptions,
            itemTemplate: true,
        },
        {
            name: "zipCode",
            type: "number",
            fieldType: 'zipCode',
            placeholder: "ZIP Code",
            label: "zip Code",
            id: "zipCode",
            maxLength: config.zipCodeLength,
        },
        {
            name: "relationEmail",
            type: "email",
            placeholder: "Email",
            label: "Email",
            id: "relationEmail",
        },

    ];

    const { control, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(schema) });


    useEffect(() => {
        let familyMembers = field?.value ? field.value : [];
        field?.onChange(familyMembers.map((member, i) => ({ ...member, id: i + 1 })))

        let schema = setValidations(formFields);
        setSchema(schema);
    }, []);
    // Open the modal
    const openModal = () => {
        reset({});
        setVisible(true);
    };

    // Close the modal
    const closeModal = () => {
        setVisible(false);
        setEditMember(null);
        reset({}); // Reset the form
    };

    // Save or update the form data
    const onSubmit = (data) => {
        let familyMembers = field.value ? field.value : []
        data.name = data.firstName + " " + data.lastName;
        if (editMember) {
            // Update existing member
            familyMembers = familyMembers.map((member) =>
                member.id === editMember.id ? { ...member, ...data } : member
            );

        } else {
            familyMembers.push({ ...data, id: familyMembers.length + 1 });
        }
        field.onChange(familyMembers);
        closeModal();
    };

    // Delete a family member
    const deleteFamilyMember = (id) => {
        let familyMembers = field.value ? field.value : []
        field.onChange(familyMembers.filter((member) => member.id !== id));
    };

    // Edit a family member
    const editFamilyMember = (rowData) => {
        setEditMember(rowData);
        reset(rowData); // Pre-fill the form with the selected row's data
        setVisible(true); // Open the modal for editing
    };

    // Action buttons for DataTable
    const actionBodyTemplate = (rowData) => {
        return (
            <div className='d-flex justify-content-center'>
                <FontAwesomeIcon
                    className='genderIconAlignment mx-2'
                    color='white'
                    icon='trash-alt'
                    data-toggle="tool-tip"
                    title="Delete"
                    style={{ color: '#bf1725', width: '13', marginLeft: 10, cursor: 'pointer' }}
                    onClick={() => deleteFamilyMember(rowData.id)} />

                <FontAwesomeIcon
                    className='genderIconAlignment'
                    color='white'
                    icon='edit'
                    data-toggle="tool-tip" title="Edit"
                    style={{ color: '#024a88', width: '15', cursor: 'pointer' }}
                    onClick={() => editFamilyMember(rowData)} />
            </div>
        );
    };


    const footer = () => <div>
        <Button label="Back" onClick={closeModal} />
        <Button label={editMember ? 'Update' : 'Save'} onClick={handleSubmit(onSubmit)} />
    </div>



    return <div className='family_members'>
        {/* Add Family Member Button */}
        <div className='d-flex mb-1'>
            <h4>Add Family Member</h4>

            <Button type='button' rounded className=" mx-1" onClick={openModal} style={{ width: '1.8rem', height: '1.8rem', padding: '5px' }}>
                <span style={{ fontWeight: 'bolder' }} className="pi pi-plus" />
            </Button>
        </div>

        {/* Modal for Adding/Editing Family Members */}
        <Dialog header={editMember ? 'Edit Family Member' : 'Add Family Member'} visible={visible} style={{ width: '50vw' }} onHide={closeModal} footer={footer}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="d-flex flex-wrap mt-2">

                    {formFields.map((item, index) => <div className="pb-2 px-2 col-sm-12 col-md-6">
                        <div>
                            <Controller
                                name={item.name}
                                control={control}
                                render={(field) => getField(field, item, errors)} />
                        </div>
                    </div>
                    )}

                </div>

            </form>
        </Dialog>

        {/* DataTable to Display Family Members */}
        <DataTable value={field.value} className='w-100' >
            <Column field="name" className='text-capitalize' header="Name" sortable />
            <Column field="address" header="Address" sortable />
            <Column field="relationEmail" header="E-Mail" sortable />
            <Column body={actionBodyTemplate} header="Actions" />
        </DataTable>
    </div>

};

export default FamilyMembers;