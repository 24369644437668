import React, { PureComponent, useEffect, useState, memo } from 'react';
import {
  Card, CardBody, Col,
} from 'reactstrap';


// config
import fetch from '../../../../config/service';
import apiCalls from '../../../../config/apiCalls';
import config from '../../../../config/config';
import { useNavigate } from 'react-router-dom';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from 'moment';

import { Button } from 'primereact/button';
// import { Card } from 'primereact/card';
import DoInputField from '../../../Form/Fields/DoInputField';
import DoDateField from '../../../Form/Fields/DoDateField';
import DoPasswordField from '../../../Form/Fields/DoPasswordField';
import DoTextareaField from '../../../Form/Fields/DoTextareaField';
import DoFileUplaod from '../../../Form/Fields/DoFileUpload';
import DoPatientPhoneField from '../../../Form/Fields/DoPatientPhone';
import DoSelectField from '../../../Form/Fields/DoSelectField';
import DoMultiSelectField from '../../../Form/Fields/DoMultiSelectField';
import DoRadioButtons from '../../../Form/Fields/DoRadioButtons';
import DoAutoCompleteField from '../../../Form/Fields/DoAutoCompleteField';
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';
import formatDate from '../../../UI/FormatDate/formatDate';
import FamilyMembers from '../../../Register/components/FamilyMembers';


// Memoize the field components for performance optimization
const MemoizedDoInputField = memo(DoInputField);
const MemoizedDoPatientPhoneField = memo(DoPatientPhoneField);
const MemoizedDoDateField = memo(DoDateField);
const MemoizedDoSelectField = memo(DoSelectField);
const MemoizedDoMultiSelectField = memo(DoMultiSelectField);
const MemoizedDoRadioButtons = memo(DoRadioButtons);
const MemoizedDoAutoCompleteField = memo(DoAutoCompleteField);


const Profile = (props) => {

  const [stateOptions, setStateOptions] = useState();
  const [selectedFamilyMembers, setSelectedFamilyMembers] = useState([]);
  const [phoneFieldVisibility, setPhoneFieldVisibility] = useState({
    father: false,
    mother: false,
    brotherOrSister: false,
    other: false,
  });
  const titleOptions = [
    { label: "Mr", value: "Mr", color: "primary" },
    { label: "Mrs", value: "Mrs", color: "primary" },
    { label: "Miss", value: "Miss", color: "primary" },
    { label: "Ms", value: "Ms", color: "primary" },
  ];

  const positionOptions = [
    { label: "Single", value: "Single", color: "primary" },
    { label: "Married", value: "Married", color: "primary" },
    { label: "Child", value: "Child", color: "primary" },
    { label: "Widowed", value: "Widowed", color: "primary" },
    { label: "Divorced", value: "Divorced", color: "primary" },
  ];

  const profileFields = [
    {
      name: "clinicIdSearch",
      type: "text",
      placeholder: "Clinic ID",
      label: "Clinic ID",
      id: "clinicIdSearch",
      required: true,
      disabled: true,
      show:true
    },
    {
      name: "providerSearch",
      type: "text",
      placeholder: "Provider",
      label: "Provider",
      id: "providerSearch",
      required: true,
      disabled: true,
      show:true
    },
    {
      name: "fullID",
      type: "text",
      placeholder: "ID",
      label: "ID",
      id: "patientId",
      required: true,
      disabled: true,
      show:true
    },
    {
      name: "title",
      type: "dropDown",
      placeholder: "Title",
      label: "Title",
      id: "title",
      options: titleOptions,
      fieldType: "dropDown",
      show:true
    },
    { "name": "firstName", "type": "text", "placeholder": "First Name", "label": "First Name", "width": 110, "id": "name", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, required: true, "fieldType": "Link", "displayinregisterForm": "true", "disabled": false, "globalSearchField": "true", "show": true, "addFormOrder": 1, "editFormOrder": 1, "mobile": true, "displayInSettings": true ,show:true},
    { "name": "lastName", "type": "text", "placeholder": "Last Name", "label": "Last Name", "width": 110, "id": "name", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, required: true, "fieldType": "Link", "displayinregisterForm": "true", "disabled": false, "globalSearchField": "true", "show": true, "addFormOrder": 1, "editFormOrder": 1, "mobile": true, "displayInSettings": true,show:true },
    { "name": "email", "type": "email", "placeholder": "Email", "label": "Email", "id": "email", "width": 150, "displayinaddForm": "true", "displayineditForm": "false", "displayinlist": "true", "controllerName": null, required: true, "displayinregisterForm": "true", "disabled": true, "show": false, "globalSearchField": "true", "addFormOrder": 2, "editFormOrder": 2, "mobile": true, "displayInSettings": true, disabled: true,show:true ,enableEmails:true
    },
    {
      name: "salutation",
      type: "text",
      placeholder: "Salutation",
      label: "Salutation",
      width: "130px",
      addFormOrder: 8,
      editFormOrder: 8,
      derivedValue: "salutation=undefined",
      capitalizeTableText: false,
      sortable: false,
      isClickable: false,
      filter: false,
      actions: [],
      actionsNumber: [],
      id: "salutation",
      displayinaddForm: "true",
      displayineditForm: "true",
      displayinlist: "true",
      isFieldRequired: "true",
      required: false,
      displayOptionsInActions: false,
      globalSearchField: "true",
      controllerId: null,
      show: true,
      showOrHideFields: [],
      mobile: true,
      displayInSettings: true,
      isAddFormHidden: false,
      isEditFormHidden: false,
      show:true
    },
    {
      name: "gender",
      type: "radio",
      placeholder: "Gender",
      label: "Gender",
      id: 'gender',
      required: true,
      options: [
        { label: "Male", value: "Male", color: "primary" },
        { label: "Female", value: "Female", color: "primary" },
        { label: "Unknown", value: "Unknown", color: "primary" },
      ],
      show:true
    },
    {
      name: "dateOfBirth",
      type: "date",
      maxDate: true,
      label: "DOB",
      disabled: true,
      id: "dateOfBirth",
      required: true,
      dateFormat: config.dateDBFormat,
      customValidation: dOBValidation,
      placeholder: config.dateDBFormat,
      show:true
    },
    {
      name: "phoneNumber",
      type: 'number',
      placeholder: "Phone",
      label: "Phone",
      disabled: true,
      maxLength: config.phoneNumberLength,
      id: "phoneNumber",
      fieldType: 'phone',
      required: true,
      show:true,
      enableTexts:true,
      enableCalls:true
    },
    {
      name: "familyMemberPhone",
      type: "dropDown",
      label: "Family Members Phone",
      isMultiSelect: true,
      id: "familyMemberPhone",
      required: false,
      placeholder: "Select family members",
      show:true,
      options: [
        { label: "Father", value: "Father" },
        { label: "Mother", value: "Mother" },
        { label: "Brother/Sister", value: "Brother/Sister" },
        { label: "Other", value: "Other" },
      ],
      onChange: setSelectedFamilyMembers
    },
    {
      name: "fatherPhoneNumber",
      type: "number",
      placeholder: "Father Phone Number",
      label: "Father Phone Number",
      maxLength: 10,
      required: phoneFieldVisibility?.father,
      show:phoneFieldVisibility?.father
    },
    {
      name: "motherPhoneNumber",
      type: "number",
      placeholder: "Mother Phone Number",
      label: "Mother Phone Number",
      maxLength: 10,
      required: phoneFieldVisibility?.mother,
      show:phoneFieldVisibility?.mother
    },
    {
      name: "brotherOrSisterPhoneNumber",
      type: "number",
      placeholder: "Brother or Sister Phone Number",
      label: "Brother or Sister Phone Number",
      maxLength: 10,
      required: phoneFieldVisibility?.brotherOrSister,
      show:phoneFieldVisibility?.brotherOrSister
    },
    {
      name: "otherPhoneNumber",
      type: "number",
      placeholder: "Other Phone Number",
      label: "Other Phone Number",
      maxLength: 10,
      required: phoneFieldVisibility?.other,
      show:phoneFieldVisibility?.other
    },
    {
      name: "preferredDay",
      type: "dropDown",
      placeholder: "Appointment Preferred Day",
      label: "Appointment Preferred Day",
      id: "preferredDay",
      options: [
        { label: "Monday", value: "Monday" },
        { label: "Tuesday", value: "Tuesday" },
        { label: "Wednesday", value: "Wednesday" },
        { label: "Thursday", value: "Thursday" },
        { label: "Friday", value: "Friday" },
        { label: "Saturday", value: "Saturday" },
        { label: "Sunday", value: "Sunday" }
      ],
      fieldType: "dropDown",
      isMultiSelect: true,
      show:true
    },
    {
      name: "preferredSession",
      type: "dropDown",
      placeholder: "Appointment Preferred Time",
      label: "Appointment Preferred Time",
      id: "preferredSession",
      options: [
        { label: "Morning", value: "Morning" },
        { label: "Afternoon", value: "AfterNoon" },
        { label: "Evening", value: "Evening" },
      ],
      fieldType: "dropDown",
      show:true
    },
    {
      name: "position",
      type: "dropDown",
      placeholder: "Position",
      label: "Position",
      id: "position",
      options: positionOptions,
      fieldType: "dropDown",
      show:true
    },
    {
      name: "chartNumber",
      type: "number",
      placeholder: "Chart Number",
      label: "Chart Number",
      id: "chartNumber",
      show:true
    },
    {
      name: "address",
      type: "text",
      placeholder: "Address",
      label: "Address",
      id: "address",
      required: true,
      show:true
    },
    {
      name: "address2",
      type: "text",
      placeholder: "Address2",
      label: "Address2",
      id: "address2",
      show:true
    },
    {
      name: "city",
      type: "text",
      placeholder: "City",
      label: "City",
      id: "city",
      required: true,
      show:true
    },
    {
      name: "state",
      type: "dropDown",
      placeholder: "State",
      label: "State",
      id: "state",
      required: true,
      options: stateOptions,
      filter: true,
      itemTemplate: true,
      show:true
    },
    {
      name: "zipCode",
      type: "number",
      placeholder: "ZIP Code",
      label: "ZIP Code",
      id: "zipCode",
      required: true,
      fieldType: 'zipCode',
      maxLength: config.zipCodeLength,
      show:true
    },
    {
      name: "familyMember",
      type: "customType",
      customType: FamilyMembers,
      placeholder: "familyMember",
      label: "Family Member",
      id: "familyMember",
      customWidthClass: 'col-md-12 col-sm-12',
      show:true
    },
  ]

  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  const [schema, setSchema] = useState('');

  useEffect(() => {
    setStateData();
    getUserData();
    setFormFieldValidation();
  }, []);


  let {
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  function dOBValidation(yup) {
    return yup.date()
      .required('Date of Birth is required')
      .test('age', 'Age must be between 1 and 100 years', (value) => {
        const today = new Date();
        const birthDate = new Date(value);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();

        // Adjust age if the birth date hasn't occurred yet this year
        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }

        return age >= 1 && age <= 100;
      })
  }
  
  useEffect(() => {
    const previousVisibility = phoneFieldVisibility;
    const newVisibility = {
      father: selectedFamilyMembers?.includes("Father"),
      mother: selectedFamilyMembers?.includes("Mother"),
      brotherOrSister: selectedFamilyMembers?.includes("Brother/Sister"),
      other: selectedFamilyMembers?.includes("Other"),
    };
    setPhoneFieldVisibility(newVisibility);

    // Check changes from true to false and reset if needed
    if (previousVisibility?.brotherOrSister && !newVisibility?.brotherOrSister) {
      setValue("brotherOrSisterPhoneNumber", "");
    }
    if (previousVisibility?.father && !newVisibility?.father) {
      setValue("fatherPhoneNumber", "");
    }
    if (previousVisibility?.mother && !newVisibility?.mother) {
      setValue("motherPhoneNumber", "");
    }
    if (previousVisibility?.other && !newVisibility?.other) {
      setValue("otherPhoneNumber", "");
    }

  }, [selectedFamilyMembers]);

  async function setStateData() {
    let statesData = [];
    if (localStorage.StatesOptions) {
      statesData = await JSON.parse(localStorage.StatesOptions);
    } else {
      let res = await fetch('GET', apiCalls.Settings).then(res => {
        if (res.settings[0]?.states?.length) {
          statesData = res.settings[0]?.states.map(option => { return { label: option.split('-')[1].trim(), value: option, displayLabel: option } })
        }
      })
      localStorage.setItem('StatesOptions', JSON.stringify(statesData));
    }
    setStateOptions(statesData);
  }

 
  let getValidations = (field) => {
    const errMsg = `${field.label} is a required field`;
    if (field.customValidation) {
      return field.customValidation(yup);
    }
    else if (field.type === 'number') {
      if (field.fieldType === 'phone') {
        return yup.string()
          .length(config.phoneNumberLength, `Enter the Valid ${field.label}`)
          .required(errMsg)
          .test('empty-string', errMsg, value => value !== '');
      } else if (field.fieldType === 'zipCode') {
        let schema = yup.string()
          .length(config.zipCodeLength, `Enter a valid ${field.label} of exactly ${config.zipCodeLength} characters`)
          .test('empty-string', errMsg, value => value !== '');
  
        return field.required ? schema.required(errMsg) : schema;
      }
      return yup.number()
        .typeError(errMsg) 
        .required(errMsg)
        .test('empty-string', errMsg, value => value !== null);
    } else if (field.type === 'email') {
      if (field.required) {
        return yup.string().email().required(errMsg).test({
          name: 'email-regex',
          test: function (value, { originalValue }) {
            if (originalValue) {
              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
              if (!emailRegex.test(originalValue)) {
                throw this.createError({ path: field.name, message: 'Enter a valid email id' });
              }
            }
            return true;
          },
        });
      }

      return yup.string().test({
        name: 'email-regex',
        test: function (value, { originalValue }) {
          if (originalValue) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(originalValue)) {
              throw this.createError({ path: field.name, message: 'Enter a valid email id' });
            }
          }
          return true;
        },
      });
    } else {
      return yup.string().required(errMsg);
    }
  }
  
   useEffect(() => {
      setTimeout(() => {
        const schema = setValidations(profileFields);
        setSchema(schema);
      }, 180);
     
    }, [phoneFieldVisibility]);
  
  const setValidations = (fields, initial) => {
    let valObj = {};
    fields.forEach((field) => {
      if (field.required || field.type == 'email' || field.fieldType === 'zipCode') {
        valObj[field.name] = getValidations(field);
      }
    });
    return yup.object().shape(valObj)
  }

  async function setFormFieldValidation() {

    const tSchema = await setValidations(profileFields,true);
    setSchema(tSchema);
  }


  // get data from server based on Id
  const getUserData = async () => {
    if (localStorage.getItem('loginCredentials')) {
      let user = JSON.parse(localStorage.getItem('loginCredentials'));
      let Url = `${apiCalls.patients}/${user._id}`;
      return fetch('GET', Url)
        .then(async (response) => {
          let data = response?.details;
          if (data) {
            for (let formField of profileFields) {
              if (formField.type === 'date' && data[formField.name]) {
                data[formField.name] = new Date(data[formField.name]);
              }
            }

            setUserData({ ...data });
            user = Object.assign(user, data)
            await localStorage.setItem('loginCredentials', JSON.stringify(user));
            if (data.photo) {
              updateTopbarData()
            }
            reset(data);
          } else if (response && response.errorMessage) {

          }
        }).catch((err) => {
          return err;
        });
    }
    else {
      return;
    }
  }
  const updateTopbarData = () => {
  }
  const getFileName = (file) => {
    updateTopbarData();
  }

  const onClickUpdate = (values) => {
    if (values.dateOfBirth) {
      values.Dob = moment(values.dateOfBirth).format(config.dateDBFormat);
    }

    for (let formField of profileFields) {
      if (formField.type === 'date' && values[formField.name]) {
        values[formField.name] = !isNaN((new Date(values[formField.name])).getTime()) ? formatDate.formatDate(values[formField.name]) : undefined;
      }
    }
    updateProfile({ ...values });
  }

  const updateProfile = (formData) => {
    if (formData) {
      delete formData.email;
      delete formData.password;
      let Url;
      if (localStorage.getItem('loginCredentials')) {
        let user = JSON.parse(localStorage.getItem('loginCredentials'));
        Url = `${apiCalls.patients}/${user._id}`;
      }
      delete formData.__v;
      return fetch('PUT', Url, formData)
        .then(async (response) => {
          if (response && response.respCode && response.respCode === 205) {
            showToasterMessage(response.respMessage, 'success');
            await getUserData();
            window.location.reload();
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, 'error');
          }
        }).catch((err) => {
          return err;
        });

    } else {
      return;
    }

  }

  const handleBack = () => {
    navigate(`/${config.defaultScreen}`);
  };

  const resetData = () => {
    reset(userData);
  }

  const getFieldComponent = (item) => {
    switch (item.fieldType || item.type) {
      case "relateAutoComplete": return MemoizedDoAutoCompleteField;
      case 'phone': return MemoizedDoPatientPhoneField;
      case 'date': return MemoizedDoDateField;
      case 'dropDown': return item.isMultiSelect ? MemoizedDoMultiSelectField : MemoizedDoSelectField;
      case 'radio': return MemoizedDoRadioButtons;
      default: return MemoizedDoInputField;
    }
  };

  const getField = ({ field, fieldState }, item, customErrors) => {

    const componentProps = {
      markReq: item.required,
      input: field,
      item: item,
      id: field.id,
      name: field.name,
      label: item.label,
      maxDate: item.maxDate,
      options: item.options,
      type: item.type === 'number' ? 'number' : 'text',
      fieldState,
      errors: customErrors ? customErrors : errors,
      control,
      onChange:item?.onChange,
      formProps: props,
      defVal: item.value,
      placeholder: item.placeholder || null,
      getValues,
      setValue,
      watch,
      field,
      fieldState,
      stateOptions,
      getFieldComponent,
      setValidations,
      getField,
    }
    const FieldComponent = item.type === "customType" && item.customType ? item.customType : getFieldComponent(item);

    return <FieldComponent
      {...componentProps}
    />
  }

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Profile</h5>
          </div>
          <form onSubmit={handleSubmit(onClickUpdate)}>

            <div className='row'>
              {profileFields && profileFields.length > 0 ?
                profileFields.map((item, index) => {
                  if (item.show) {
                    return (
                      <div className={`pb-2 ${item.customWidthClass ? item.customWidthClass : 'col-sm-6 col-md-4'}`}>
                        <Controller
                          name={item.name}
                          control={control}
                          render={(field) => getField(field, item)} />
                      </div>
                    );
                  }
                  return null;
                })
                : null
              }
              <div className='col-sm-12 col-md-6'>
                <div className="form__form-group">
                  <span className="form__form-group-label">Profile Image</span>
                  <div className="form__form-group-field patient-img-upload">

                    <Controller
                      name={'image'}
                      control={control}
                      render={({ field, fieldState }) => (
                        <DoFileUplaod
                          field={field}
                          url={"uploads/uploadAttachments?type=patients"}
                          type={'profile'}
                          acceptType="image/*"
                          showPreview={false}
                          userClickUpload={true}
                        />)}
                    />
                  </div>

                </div>
              </div>

            </div>


            <div className='d-flex mt-3 justify-content-end'>
              <Button type="button" size='sm' color='primary' label='Back' className='me-3' onClick={handleBack} />
              <Button type="button" size='sm' color='primary' label='Reset' className='me-3' onClick={() => resetData()} />
              <Button type='submit' size='sm' color='primary' label='Update' />
            </div>


          </form>
        </CardBody>
      </Card>
    </Col >
  );
}




export default Profile;