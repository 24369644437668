import React, { PureComponent, useEffect, useState } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import { Link } from 'react-router-dom';
import TopbarMenuLink from './TopbarMenuLink';
import config from '../../../config/config';
import configImages from '../../../config/configImages';
const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;
const noProfileImage = configImages.defaultImg;

const TopbarProfile = (props) => {
  
  const [collapse, setCollapse] = useState(false);
  const [userData, setUserData] = useState({});

  const toggle = () => {    
    setCollapse((prevState) => {return !prevState});
  };

  
  useEffect(() => {
    updateTopbarProfileData()
    return () => {
      
    };
  }, []);

  const updateTopbarProfileData = async () => {
    let locUserData = await localStorage.getItem('loginCredentials');
    if(locUserData){
      setUserData(JSON.parse(locUserData));
      
    }   
  }


  
  const profileImage = (userData && userData.image) ? userData.image[0] : null;

    return (
      <div className="topbar__profile">
        <button className="topbar__avatar" type="button" onClick={toggle}>
          {/* change */}
          <img className="topbar__avatar-img"
            src={profileImage ? `${config.profileUrl}${profileImage}` : noProfileImage} />
          <p className="topbar__avatar-name text-capitalize-first-letter">{userData && userData.name ?
            userData.name : 'No Name'}</p>
          <DownIcon className="topbar__icon" />
        </button>
        {collapse && <button className="topbar__back" type="button" onClick={toggle} />}
        <Collapse isOpen={collapse} className="topbar__menu-wrap">
          <div className="topbar__menu topbarMenu">
            <div className='topBarImageAlignment'>
              <img src={profileImage ? `${config.profileUrl}${profileImage}` : noProfileImage}
                className='topBarImage' />
              <div className='text-capitalize-first-letter'>
                {userData && userData.name ?
                  userData.name : 'No Name'}
                <div className='themeColorText'>{userData &&
                  userData.role ?
                  userData.role : ''}</div>
              </div>
            </div>

            <div className="topbar__menu-divider" />
            <div className="topbar__menu">
              <div>
                <Link className="topbar__link"
                  to={{ pathname: `/profile`, state: { updateTopbarProfileData: props.updateTopbarProfileData } }}
                  onClick={toggle}>
                  <span className={`topbar__link-icon lnr lnr-user`} />
                  <p className="topbar__link-title">{"My Profile"}</p>
                </Link>
              </div>

              {/* <div>
                <Link className="topbar__link"
                  to={{ pathname: `/changePassword`, state: { updateTopbarProfileData: props.updateTopbarProfileData } }}
                  onClick={toggle}>
                  <span className={`topbar__link-icon lnr lnr-bubble`} />
                  <p className="topbar__link-title">{"Change Password"}</p>
                </Link>
              </div> */}
              {
                config.displaySettings ?
                  <div >
                    <Link className="topbar__link"
                      to={{ pathname: `/adminSettings`, state: { updateTopbarProfileData: props.updateTopbarProfileData } }}
                      onClick={toggle}>
                      <span className={`topbar__link-icon lnr lnr-cog`} />
                      <p className="topbar__link-title">{"Settings"}</p>
                    </Link>
                  </div> : null
              }
              <div className="topbar__menu-divider" />
              <TopbarMenuLink title="Log Out" icon="exit" path="/log_in" toggleClose={toggle}
                updateTopbarProfileData={updateTopbarProfileData}
              />
            </div>
          </div>
        </Collapse>
      </div >
    );
  }
export default TopbarProfile;
